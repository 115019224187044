export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
    action: 'read',
    resource: 'Admin',
  },
  
  {
    title: 'Inicio',
    route: 'home-credor',
    icon: 'HomeIcon',
    action: 'read',
    resource: 'Credor',
  },
  {
    header: 'Meus cadastros',
    action: 'read',
    resource: 'Credor',
  },
  {
    title: 'Ver devedores',
    route: 'cliente-credor',
    icon: 'UsersIcon',
    action: 'read',
    resource: 'Credor',
  },
  {
    title: 'Ver títulos',
    route: 'titulo-credor',
    icon: 'DollarSignIcon',
    action: 'read',
    resource: 'Credor',
  },
  
  {
    header: 'Cadastros',
    action: 'read',
    resource: 'Admin',
  },
  {
    title: 'Credores',
    route: 'credor-list',
    icon: 'BriefcaseIcon',
    action: 'read',
    resource: 'Admin',
  },
  {
    title: 'Devedores',
    route: 'cliente-list',
    icon: 'UsersIcon',
    action: 'read',
    resource: 'Admin',
  },
  {
    title: 'Títulos',
    route: 'titulo-list',
    icon: 'DollarSignIcon',
    action: 'read',
    resource: 'Admin',
  },
  {
    header: 'Cobrança',
    action: 'read',
    resource: 'Admin',
  },
  {
    title: 'Cobranças',
    route: 'cobranca-list',
    icon: 'SendIcon',
    action: 'read',
    resource: 'Admin',
  },
  {
    title: 'Cartas',
    route: 'carta-list',
    icon: 'FileTextIcon',
    action: 'read',
    resource: 'Admin',                                                      
  },
  {
    title: 'Mensagens',
    route: 'mensagem-list',
    icon: 'MessageSquareIcon',
    action: 'read',
    resource: 'Admin',
  },
  {
    title: 'Régua de Cobrança',
    route: 'collection-index',
    icon: 'SlidersIcon',
    action: 'read',
    resource: 'Admin',
    disabled: true
  },
  {
    header: 'Geral',
    action: 'read',
    resource: 'Admin',
  },
  {
    title: 'Importações',
    route: 'importacao-list',
    icon: 'UploadCloudIcon',
    action: 'read',
    resource: 'Admin',
  },
  {
    title: 'Relatórios',
    icon: 'PieChartIcon',
    action: 'read',
    resource: 'Admin',
    children: [
      {
        title: 'Histórico de cobranças',
        route: 'relatorio-cobrancas',
        icon: 'RefreshCwIcon',
        action: 'read',
        resource: 'Admin',
      },
      {
        title: 'Relatórios em Excel',
        route: 'relatorio-excel',
        icon: 'FileIcon',
        action: 'read',
        resource: 'Admin'
      },
    ],
  },
  {
    title: 'Usuários',
    route: 'usuario-list',
    icon: 'UserIcon',
    action: 'read',
    resource: 'Admin',
  },
  {
    title: 'Configurações',
    route: 'settings',
    icon: 'SettingsIcon',
    action: 'read',
    resource: 'Admin',
  },
]
